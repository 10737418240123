import React from 'react';

import Layout from '../components/Layout';
import { graphql } from 'gatsby';

import Header from '../components/Header';
import Seo from '../components/Seo';
import Loadable from '@loadable/component';

const Footer = Loadable(() => import('../components/Footer'));
const SocialLinks = Loadable(() => import('../components/SocialLinks'));

const Blog = Loadable(() => import('../components/Blog'));
const JumpToSection = Loadable(() => import('../components/JumpToSection'));

const IndexPage = ({ ...props }) => {
  return (
    <Layout>
      <Seo />
      <Header
        frontPage={true}
        title="Artigos"
        subtitle="Sabia que..."
        active="artigos"
      />

      {<Blog {...props} />}
      <SocialLinks />
      <Footer />
      <JumpToSection />
    </Layout>
  );
};

export default IndexPage;

export const postQuery = graphql`
  query allPosts {
  allPrismicPost(
    limit: 5
    filter: {data: {published: {eq: true}}}
    sort: {data: {date: DESC}}
  ) {
    edges {
      node {
        data {
          slug
          title {
            text
          }
          published
          category
          date
          author {
            document {
              ... on PrismicAuthor {
                type
                data {
                  name
                  listed
                  customer
                  internallink
                }
              }
            }
          }
          keywords {
            keyword
          }
          description {
            html
            text
          }
          content {
            html
            text
          }
          cover {
            url
            gatsbyImageData
          }
        }
      }
    }
  }
}`;
